@import "../styles/vars"

.site-menu
  display: flex
  flex-direction: column
  justify-content: space-evenly

  @media (max-width: $md)
    padding-top: $rootlet-width
    justify-content: start

  > .images
    position: absolute
    inset: 0
    top: 0
    bottom: 0
    left: 0
    right: 0
    overflow: hidden

    pointer-events: none

    @media (max-width: $md)
      inset: unset
      top: unset 
      bottom: 0
      height: 60%
      width: 100%

    > picture
      position: absolute
      height: 100%
      width: 100%

      > img
        $time: .4s
        position: absolute
        height: 100%
        width: 100%

        filter: brightness(50%)
        opacity: 0
        object-fit: cover
        transition: opacity $time linear

        &.-active
          opacity: 1
          transition-delay: $time/2

    [data-section='main']
      object-position: bottom
      margin-left: 40vw

    [data-section='aboutUs']
      object-position: top right
      object-fit: contain
      left: 30%
      width: 65%
      top: 3rem

      @media (max-width: $md)
        height: 200%
        width: 100%
        top: -15%
        object-position: unset

      @media (max-width: $xs)
        top: -15%

    [data-section='portfolio']
      object-position: top right
      right: 2%
      width: 86%
      top: 3rem
      max-width: 1250px

      @media (max-width: $sm)
        right: -25%
        width: 124%

    [data-section='contact']
      object-fit: contain
      object-position: bottom right
      left: 30%
      width: 65%
      top: 3rem

      @media (max-width: $sm)
        left: 10%
        height: 143%
        width: 116%

      @media (max-width: $xs)
        left: 25%
        top: -40%
  nav
    > .item
      cursor: pointer
      display: block
      padding-top: 1.5rem
      padding-bottom: 2.5rem
      padding-left: 4rem
      margin-bottom: 1rem
      position: relative

      font-family: "Futura PT Demi", sans-serif
      font-size: 6rem
      text-decoration: none
      color: transparent
      -webkit-text-stroke: 1px $second-text
      text-stroke: 1px $second-text

      @media (max-width: $md)
        font-size: 4rem
        padding-bottom: 0

      @media (max-width: $xs)
        font-size: 3rem
        padding-left: 2rem

      &:hover
        color: white
        -webkit-text-stroke: 1px white
        text-stroke: 1px white

      &.-active
        -webkit-text-stroke: 1px white
        text-stroke: 1px white

      sup
        display: inline-block
        margin-right: 2rem

        vertical-align: text-top

        font-family: "Futura PT", sans-serif
        font-size: 2rem
        font-weight: normal
        color: $accent-color

        -webkit-text-stroke: 0 transparent
        text-stroke: 0 transparent

        @media (max-width: $md)
          margin-right: 1rem

        @media (max-width: $xs)
          font-size: 1rem

        &::before
          content: '0'
